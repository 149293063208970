
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { NextPage } from "next";
import Meta from "~/components/common/Meta/Meta";
import NotFound from "~/components/servicePages/NotFound";
const Page404: NextPage = (): JSX.Element => (<>
    <Meta pageKey="404"/>
    <NotFound />
  </>);
export default Page404;

    async function __Next_Translate__getStaticProps__18c1550bff8__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18c1550bff8__ as getStaticProps }
  