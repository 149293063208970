import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import NextLink from "next/link";
import useTranslation from "next-translate/useTranslation";

import ROUTES from "~/constants/routes";
import Image404 from "~/public/assets/images/404.svg?url";
import PlasticRemoval from "~/public/assets/images/brandLogos/PlasticRemovalLogo.png";
import PlasticRemoval2x from "~/public/assets/images/brandLogos/PlasticRemovalLogo@2x.png";
import { buildSrcSetForRetina } from "~/utils/images/buildSrcSetForRetina";

import ServicePageBase from "./ServicePageBase";

const NotFound = (): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <StyledServicePageBase
      title={t("page-not-found_title")}
      text={t("page-not-found_description")}
      image={
        <>
          <CodeImage src={Image404} />
          <PlasticRemovalImage
            srcSet={buildSrcSetForRetina(
              PlasticRemoval.src,
              PlasticRemoval2x.src
            )}
            alt={t("page-not-found")}
          />
        </>
      }
    >
      <GoBackButton
        LinkComponent={NextLink}
        size="large"
        color="brandGreen"
        href={ROUTES.index}
      >
        {t("go-back")}
      </GoBackButton>
    </StyledServicePageBase>
  );
};

export default NotFound;

const StyledServicePageBase = styled(ServicePageBase)`
  background: ${({ theme }) => theme.palette.brandBlue.main};
`;

const CodeImage = styled.img`
  max-width: 850px;
  width: 100%;
`;

const PlasticRemovalImage = styled.img`
  left: 50%;
  margin-top: 5%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
`;

const GoBackButton = styled(Button)`
  width: 182px;
`;
