import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  className?: string;
  image: ReactNode;
  title: string;
  text: string;
  children: ReactNode;
};

const ServicePageBase = ({
  className,
  image,
  title,
  text,
  children
}: Props): JSX.Element => (
  <Wrapper className={className}>
    <Stack alignItems="center">
      <ImageContainer>{image}</ImageContainer>
      <Caption variant="h3">{title}</Caption>
      <Text
        variant="paragraphTertiary"
        whiteSpace="pre-wrap"
        textAlign="center"
      >
        {text}
      </Text>
      <Content>{children}</Content>
    </Stack>
  </Wrapper>
);

export default ServicePageBase;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing(-22)};
  padding: ${({ theme }) => theme.spacing(40, 4)};
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: ${({ theme }) => theme.spacing(-23)};
  }
`;

const ImageContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(32.5)};
  position: relative;
`;

const Caption = styled(Typography)`
  color: ${({ theme }) => theme.palette.static.text.light.quaternary};
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    text-align: center;
  }
`;

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.static.text.light.quaternary};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    white-space: normal;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;
